<div class="mainform">
	<br>
	<mat-form-field appearance="outline" class="search_zone" autofocus>
		<input matInput matNativeControl
		       title="Un ou plusieurs mots pour effectuer des recherches dans les métiers, les noms, les réalisations ..."
		       (keyup)="onQuery($event)" [(ngModel)]="query"
		       placeholder="Rechercher par année, par film, par nom du festival">
		<mat-icon *ngIf="query && query.length>0"
		          matSuffix style="cursor: pointer"
		          (click)="clearQuery()">cancel</mat-icon>
	</mat-form-field>
	<br>

	<div class="wrap-list" style="width:100%;">
		<mat-expansion-panel *ngFor="let festival of festivals"
		                     class="wrap-item" hideToggle
		                     (afterCollapse)="festival.expanded=false"
		                     [expanded]="festival.expanded">

			<mat-expansion-panel-header collapsedHeight="*" expandedHeight="*" style="padding: 10px;">
				{{festival.title}}
			</mat-expansion-panel-header>

			<app-timeline class="column"
			              style="margin-top: 10px;"
			              [data]="festival.award_timeline"
			              [field_style]="field_style"
			              [field_class]="field_class"
			              row_style="vertical-align: top;width:100%;">
			</app-timeline>
		</mat-expansion-panel>
	</div>
</div>
