<mat-card [ngStyle]="{'backgroundColor':backgroundColor,'text-align':'left','max-width':maxwidth,'min-height':minheight,'min-width':minwidth,'width':width,height:height}">
  <mat-card-header>
    <div mat-card-avatar>
      <img src="/assets/img/movie.png" style="height:40px">
    </div>
    <mat-card-title>
       {{work.job}} de {{work?.pow?.title}}
      <span *ngIf="work?.year">({{work?.year}})</span>
    </mat-card-title>
    <mat-card-subtitle *ngIf="work?.pow">
      {{work?.pow.nature}} / {{work?.pow.category}}
    </mat-card-subtitle>

  </mat-card-header>

  <mat-card-content [ngStyle]="{margin: '0px','margin-top':'5px'}">


    <ng-content></ng-content>

  </mat-card-content>

  <mat-card-actions *ngIf="showAction"
                    style="text-align: left;">

    <button mat-icon-button class="icon-button"
            *ngIf="work?.pow?.links?.length>0 && work.pow.links[0].url.length>0"
            (click)="openInfo(work.pow.links[0])"
            [title]="work?.pow.links[0].text">
      <mat-icon>info</mat-icon>
    </button>

  </mat-card-actions>
</mat-card>

