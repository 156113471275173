<div class="mainform" style="font-weight: lighter;">
  <div style="max-width: 500px;text-align: center;display: inline-block;">

    <br>
    <h3>Choisir un nouveau profil</h3>

    <app-tuto label="Les profils correspondent à des besoins particuliers, avoir le bon profil permet de simplifier l'usage de %APPNAME%"></app-tuto>

    <app-tuto label="%appname% propose différents profils d'utilisateur pour s'adapter a vos besoins et ne présentez que les informations dont vous avez réelement besoin"></app-tuto>

    <mat-accordion [multi]="false" style="text-align: left;width: 80%;max-width: 600px;">
      <div *ngFor="let profil of profils" style="width: 100%;">
        <mat-expansion-panel *ngIf="profil.subscription=='online' || profil.subscription=='email' || !config.isProd()"
                             name="tabProfils" class="mat-body-1"
                             style="margin:10px;display: inline-block;width: 100%;">

          <mat-expansion-panel-header>
            {{profil.title}}
          </mat-expansion-panel-header>

          <p>{{profil.presentation}}</p>

          <div style="text-align: left;color:lightgrey;line-height: 120%;">
            Accès:
            <div [innerHTML]="show_perm(profil.id)"></div>
            <br>
          </div>

          <div *ngIf="profil.price>0">
            <br><br>
            Prix:{{profil.price}}€/mois
          </div>

          <mat-action-row>
            <button mat-raised-button
                    name="cmdAskProfil"
                    class="app-button"
                    *ngIf="profil.subscription!='offline'"
                    (click)="sel_profil(profil)">

              <span *ngIf="profil.subscription=='online' || profil.subscription=='secure'">Souscrire</span>
              <span *ngIf="profil.subscription=='email'">Demander</span>

            </button>

          </mat-action-row>

        </mat-expansion-panel>
      </div>
    </mat-accordion>
    <br><br>
  </div>

</div>



