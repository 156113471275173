<iframe [src]="environment.link_to_news | safe"
        *ngIf="articles?.length==0"
        style="width: 100%;height: 100vh" ></iframe>

<div class="mainform" style="overflow: hidden;" *ngIf="articles?.length>0">

  <div style="margin:10px;text-align: center;width:100%;">

   <div *ngIf="articles?.length>0">
     <mat-checkbox *ngFor="let tag of allTags" class="mat-body-2"
                   [(ngModel)]="tag.checked" style="margin: 20px;"
                   (ngModelChange)="refresh()">
       {{tag.label}}
     </mat-checkbox>

   </div>

    <button class="app-button" mat-raised-button
            id="cmdEdit" style="margin: 25px;"
            title="Rédiger un article et le soumettre à la modération pour publication sur Data Culture"
            *ngIf="config.hasPerm('publish')"
            (click)="router.navigate(['htmledit'])">
      <div class="bloc-bouton">Soumettre<br>un article</div>
    </button>


<!--    <a href="https://www.femis.fr/-actualites-" class="mat-headline-6">Actualité de la FEMIS</a>-->

    <mat-expansion-panel *ngFor="let article of articles"
                         class="mat-elevation-z2"
                         style="width:95%;background-color: #817a7a;margin:1%;padding:10px;border-radius:6px;text-align: left;position: relative;">

      <mat-expansion-panel-header>
        <div style="display: inline-block">
          {{article.dtPublish | date:'dd/MM/YY'}} - {{article.title}}<br>
          <span style="font-size: small;margin-bottom: 5px;opacity: 0.5;">{{article.summary}}</span>
        </div>

      </mat-expansion-panel-header>

      <div *ngIf="!article.content.startsWith('http')" [innerHTML]="article.content" style="width: 100%;height: 100%;overflow: scroll;"></div>
      <iframe *ngIf="article.content.startsWith('http')"
              [src]="article.content | safe">
      </iframe>

      <div style="position: absolute;bottom: 10px;right:10px;width:100%;z-index: 100;text-align: right;" *ngIf="config.hasPerm('validate')">
        <div *ngIf="article.to_publish && !article.validate">
          Article à publier
          <button class="app-button" mat-raised-button
                  id="cmdPublish" color="primary"
                  title="Cet article deviendra visible dans la rubrique Blog jusqu'a sa déplubication"
                  (click)="publish(article,true)">
            Publiez
          </button>
        </div>


        <button class="app-button" mat-raised-button
                id="cmdDeleteComplete"
                (click)="delete(article)">
          Supprimer
        </button>

        <button class="app-button" mat-raised-button
                id="cmdSendMessage"
                title="Envoyer un message a l'auteur pour lui expliquer la raison de la non publication"
                (click)="notif(article)">
          Notifier
        </button>

        <button class="app-button" mat-raised-button
                id="cmdEditMessage"
                *ngIf="!article.validate && article.owner==config.user.user.id"
                (click)="edit(article)">
          Editer
        </button>

        <button class="app-button" mat-raised-button
                id="cmdDelete"
                *ngIf="article.validate"
                (click)="publish(article,false)">
          Dépubliez
        </button>
      </div>


    </mat-expansion-panel>
    <br>
  </div>
</div>
