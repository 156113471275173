<div class="mainform" style="padding: 10px;">
    <app-tuto label="Ici vous gérez les films"></app-tuto>
    <app-hourglass [message]="message"></app-hourglass>
    <br>
    <div class="h-zone mat-display-1" *ngIf="!showDetail">Insérer un nouveau film</div>
    <div style="width: 50%;min-width:350px;max-width: 600px;text-align: left;display: inline-block;">
        <app-tuto label="DataCulture permet l'ajout manuel de nouveau film mais assurez vous d'avoir bien vérifier que ce film n'existait pas encore sur la plate forme IMDB ou Unifrance"></app-tuto>
        <mat-form-field class="app-field mat-subheading-2" *ngIf="!showDetail">
            <mat-label>Titre du film ?</mat-label>
            <input matInput placeholder="mon titre" id="txtTitle"
                   (ngModelChange)="changeTitle($event)"
                   type="text" [(ngModel)]="pow.title"
                   autofocus>
        </mat-form-field>


        <div *ngIf="pow.title.length>2 && !showDetail" style="height: 50vh;width: 95%;overflow: scroll;">
            <table>
                <tr>
                    <td>
                        Avant de référencer un nouveau film, assurez vous que celui ci n'existe pas déjà dans {{config.values.appname}}<br>
                        <div *ngIf="pows!.length>0">Si c'est le cas, sélectionné le dans la liste ci-dessous:</div>
                    </td>
                    <td></td>
                    <td>
                        <button class="app-button login-button"
                                *ngIf="pow.title?.length>2"
                                id="cmdAddPow"
                                mat-raised-button
                                (click)="add_title()">
                            Ajouter
                        </button>
                    </td>
                </tr>
            </table>

            <mat-selection-list #items *ngIf="!showDetail"
                                (selectionChange)="select_title(items.selectedOptions.selected[0].value)"
                                [multiple]="false">
                <mat-list-option *ngFor="let item of pows" [value]="item" name="films">
                    <mat-icon mat-list-icon>videocam</mat-icon>
                    <div mat-line>{{item.title}} ({{item.year}})</div>
                </mat-list-option>
            </mat-selection-list>
        </div>

        <div *ngIf="showDetail">
            <div class="mat-headline-4" style="width: 100%;text-align: center;">"{{pow.title}}"</div>
            <mat-accordion [multi]="false" style="text-align: left;">
                <mat-expansion-panel  [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Informations générales
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div style="width: 100%;text-align: center">
                        <img [src]="pow.visual"
                             *ngIf="pow.visual.length>0"
                             style="width:150px;"
                             (click)="change_visual()">
                        <button class="app-button login-button" color="primary"
                                id="cmdVisual"
                                *ngIf="pow.visual.length==0"
                                mat-raised-button
                                (click)="change_visual()">
                            Ajouter un visuel
                        </button>
                        <br>
                      <br>
                    </div>

                    <app-input label="Synopsis" max="3000" [cols]="30" [rows]="5" [value]="pow.description"
                               (valueChange)="pow.description=$event" value_type="memo"
                                help="Synopsis de l'oeuvre (3000 caractères max)">
                    </app-input><br>

                    <app-input label="Année de sortie" [value]="pow.year"
                               style="display:inline-block;"
                               (valueChange)="pow.year=$event" width="175px" value_type="number">
                    </app-input>

                    <app-input label="Budget"  [value]="pow.budget"
                               style="display:inline-block;"
                               (valueChange)="pow.budget=$event" width="175px" value_type="number"
                                help="En €">
                    </app-input>

                </mat-expansion-panel>

                <mat-expansion-panel  id="pnlAddLink">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Ajouter des liens associés à l'oeuvre
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div *ngFor="let link of pow.links" style="width: 80%;display: inline-block;text-align: left;font-size: small;font-weight: lighter;">
                        <mat-icon (click)="remove(link)" style="font-size: small;cursor: pointer;">cancel</mat-icon>
                        <a href="{{link.url}}" target="_blank">{{link.text}}</a>
                        <br>
                    </div>

                    <mat-form-field  appearance="fill" class="app-field">
                        <mat-label>Lien internet</mat-label>
                        <input matInput placeholder="https://"
                               title="Peut être un lien vers une plateforme vidéo, la fiche wikipedia"
                               type="text" max="300"
                               [(ngModel)]="link.url">
                        <mat-hint>Adresse internet associé à l'oeuvre</mat-hint>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="app-field">
                        <mat-label>Libellé du lien</mat-label>
                        <input matInput type="text" max="300"
                               [(ngModel)]="link.text">
                    </mat-form-field>


                </mat-expansion-panel>
            </mat-accordion>
        </div>

    </div>

</div>
<div class="bottom-bar">
    <hr>
    <input #csvInput hidden="true"
           type="file" onclick="this.value=null"
           (change)="_import($event)"
           accept=".xls,.xlsx"/>

    <button class="app-button login-button"
            mat-raised-button
            *ngIf="pow?.owner=='public' && config.hasPerm('add_movies')"
            (click)="csvInput.click()">
        Importer
    </button>

    <button class="app-button login-button"
            mat-raised-button
            *ngIf="pow?.owner=='public' && config.hasPerm('add_movies')"
            (click)="router.navigate(['profils_scanner'])">
        Analyse
    </button>

    <button class="app-button login-button"
            mat-raised-button
            [disabled]="pow?.description=='' || pow?.year==0"
            id="cmdSave" color="primary"
            (click)="add_link()">
        <div class="bloc-bouton">Ajouter<br>le film</div>
    </button>

    <button class="app-button login-button"
            mat-raised-button
            (click)="quit(false)">
        Annuler
    </button>
</div>
