import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Router} from "@angular/router";
import {Location} from "@angular/common";
import {ApiService} from "../api.service";
import {ConfigService} from "../config.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {environment} from "../../environments/environment";
import {MatDialog} from "@angular/material/dialog";
import {menu_items} from "../menu/menu.component";

@Component({
  selector: 'app-loginbar',
  templateUrl: './loginbar.component.html',
  styleUrls: ['./loginbar.component.sass']
})
export class LoginbarComponent implements OnInit {

  @Input("user") user:any;
  @Output('logout') onlogout: EventEmitter<any>=new EventEmitter();

  isLocal=false;
  menu_header:menu_items= environment.menu.header
  menu_footer:menu_items= environment.menu.footer
  status="disconnect";

  constructor(public router:Router,
              public dialog:MatDialog,
              public toast:MatSnackBar,
              public _location:Location,
              public config:ConfigService,
              public api:ApiService) {

  }

  ngOnInit(): void {
    this.isLocal=!environment.production;
    this.menu_footer.admin.actif=this.isLocal;
    this.config.user_update.subscribe((r:any)=>{
      let isLogin=r.user.email.length>0;
      this.status=isLogin ? "connect" : "disconnect";
      this.menu_footer.login.actif=!isLogin;
      this.menu_footer._logout.actif=isLogin;
      this.menu_footer.settings.actif=isLogin;
      this.menu_header.addpow.actif=isLogin && this.config.hasPerm("add_movie");
      //this.menu_header.edit.actif=isLogin && this.config.hasPerm("edit");
      this.menu_header.blog.actif=isLogin && this.config.hasPerm("blog");
      this.menu_footer.admin.actif=isLogin && this.config.hasPerm("admin") || !this.config.isProd();
      this.menu_footer.dev.actif=isLogin && this.config.hasPerm("dev");
      this.menu_header.htmledit.actif=isLogin && this.config.hasPerm("publish");
      this.menu_header.stats.actif=isLogin && this.config.hasPerm("stats");
    })
  }


  help() {
  }

  onselect($event: any) {
    if($event.icon=="logout")this.onlogout.emit();
  }
}

