import { Component, OnInit } from '@angular/core';
import {ApiService} from "../api.service";
import {showError, showMessage} from "../tools";
import {Router} from "@angular/router";

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.sass']
})
export class ImportComponent implements OnInit {
  message: string="";
  dict="{}";

  constructor(public api:ApiService,public router:Router) { }

  ngOnInit(): void {

  }

   import(excel_file: any,obj:string) {
     this.api._post("importer/","type_object="+obj,{
       file:excel_file,
       dictionnary:this.dict
     },200).subscribe((r:any)=>{
       this.message="";
       showMessage(this,r);
       this.router.navigate(["search"])
     },(err)=>{
       showError(this,err);
     })
  }


}
