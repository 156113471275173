<div class="mainform" style="text-align: center;width:100%;">

  <br>

  <div class="main-message" *ngIf="config && config.user && config.user.user">
   Bonjour,
  </div>

  <app-tuto label="Cet écran vous présente les informations liées à votre compte d'utilisateur de Data Culture"></app-tuto>

  <mat-accordion style="display: inline-block;width:90%;max-width: 800px;" *ngIf="config && config.user">
    <mat-expansion-panel  style="text-align: left;" id="pnlGeneral" [expanded]="true">
      <mat-expansion-panel-header>Général</mat-expansion-panel-header>
      <table style="border-spacing: 20px;">
        <tr>
          <th>Votre email</th>
          <td >{{config.user.user.email}}</td>
        </tr>
        <tr>
          <th>Profil d'utilisateur</th>
          <td>
            {{config.profils[config.user.profil_name]?.title}}<br>
            <span style="opacity: 0.6">{{config.profils[config.user.profil_name]?.presentation}}</span>
          </td>
        </tr>
        <tr>
          <th>Mode expert</th>
          <td>
            <mat-slide-toggle [ngModel]="config.user.level==1"
                              (click)="switch_expert_mode()"></mat-slide-toggle>
          </td>
        </tr>
      </table>

    </mat-expansion-panel>

    <mat-expansion-panel  style="text-align: left;" id="pnlPerm" *ngIf="config.user.level==1">
      <mat-expansion-panel-header>Permissions associées à votre profil</mat-expansion-panel-header>
      <div style="width: 96%;margin:2%;">
        <app-tuto label="Les permissions permettent de voir tout ce que votre profil vous permet de faire sur DataCulture"></app-tuto>
        <br>
        Actuellement vous avez accès
        <div style="text-align: left;line-height: 120%;" class="mat-body-1" [innerHTML]="show_perm"></div>
        <br>
        <br>

        <button mat-raised-button (click)="open_perms()" title="Si votre profil n'est pas adapté à votre usage de Data Culture">
          Changer de profil
        </button>

        <app-tuto label="Certaines données de Data Culture sont en accès restreint. Pour y avoir accès il peut être nécéssaire d'en faire la demande à la FEMIS"></app-tuto>

      </div>
    </mat-expansion-panel>

    <mat-expansion-panel  style="text-align: center;" id="pnlJob">
      <mat-expansion-panel-header>Confidentialité</mat-expansion-panel-header>
      <br>
      <a class="mat-body-2 primary-color" href="./rgpd">En savoir plus sur la conformité RGPD</a>
      <br>
      <br>

      <button class="app-button"
              color="primary"
              mat-raised-button
              (click)="raz()">
        Effacer votre compte
      </button>

    </mat-expansion-panel>



    <br><br>
    <button mat-raised-button (click)="open_profils()">
      Chercher dans l'annuaire
    </button>

    <button mat-raised-button (click)="open_movies()">
      Chercher des films
    </button>







  </mat-accordion>


</div>
