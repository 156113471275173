<div class="mainform" style="text-align: center !important;width: 100%;">
  <app-dbtable class="mat-elevation-z4"
               title="Liste de vos événements"
               [source]="events" style="display: inline-block;width:90%;"></app-dbtable>
  <br><br>

  <button mat-raised-button (click)="showAddEvent=true" *ngIf="!showAddEvent">
    Ajouter un événement
  </button>

  <div *ngIf="showAddEvent" style="width:90%;margin:5%;">
    <app-input label="Titre de l'événement" [max]="20" value_type="text" [value]="new_event.title" (valueChange)="new_event.title=$event"></app-input><br>
    <app-input label="Description de l'événement" [max]="200" value_type="memo" [rows]="3" [value]="new_event.description" (valueChange)="new_event.description=$event"></app-input><br>
    <app-input label="Lieu de l'événement" help="Peut être une adresse postale ou un line web" value_type="text" [value]="new_event.address" (valueChange)="new_event.address=$event"></app-input><br>

    <div style="width: 100%;display: inline-flex;">
      <app-input label="Date" value_type="date" [value]="new_event.start" (valueChange)="new_event.start=$event"></app-input>
      <app-input label="Heure de début" value_type="time" [value]="new_event.hour" (valueChange)="new_event.hour=$event"></app-input><br>
    </div>

    <app-input label="Lien web" help="Lien promotionel de l'événement" value_type="text" [value]="new_event.link" (valueChange)="new_event.link=$event"></app-input><br>
    <app-input label="Visuel" help="Lien web vers une image" value_type="text" [value]="new_event.visual" (valueChange)="new_event.visual=$event"></app-input><br>
    <img *ngIf="new_event.visual!=''" style="max-height:200px;width:80%;" [src]="new_event.visual"><br>

    <button mat-raised-button (click)="save_event()">
      Valider
    </button>

  </div>



</div>

