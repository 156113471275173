<div class="mainform">

  <app-hourglass [message]="message"></app-hourglass>
  <br>
  <mat-form-field appearance="fill" class="app-field" style="width: 70%;max-width: 250px;">
    <mat-label>Maximum de profils à analyser</mat-label>
    <input matInput type="number" [(ngModel)]="nb_profils">
  </mat-form-field>

  <mat-form-field appearance="fill" class="app-field" style="width: 70%;max-width: 250px;">
    <mat-label>Maximum de nouvelles contributions</mat-label>
    <input matInput type="number" [(ngModel)]="nb_contrib">
  </mat-form-field>

  <br>
  <button class="app-button"
          mat-raised-button
          color="primary"
          *ngIf="message.length==0"
          (click)="analyse()">
    Analyse
  </button>

  <br>
  <div *ngIf="code.length>0">
    <br>
    Proposition d'article pour les news
    <br><br>
    <div
       [innerHTML]="code"
       style="text-align:left;width: 80%;height:400px;margin-left:10%;overflow: scroll;font-size: small;"
       class="mat-elevation-z4">
  </div>
    <br>
    <button class="app-button"
          mat-raised-button
          color="primary"
          (click)="edit()">
    <div class="bloc-bouton">Editer cet<br>article</div>
  </button>
  </div>



</div>

<div class="bottom-bar">
  <hr>



  <button class="app-button login-button"
          mat-raised-button
          (click)="quit()">
    Annuler
  </button>
</div>
