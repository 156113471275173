import {Component, OnInit} from '@angular/core';
import {checkLogin, getParams, now, showMessage} from "../tools";
import {ActivatedRoute, Router} from "@angular/router";
import {DbtableComponent} from "../dbtable/dbtable.component";
import {ApiService} from "../api.service";
import {MatButton} from "@angular/material/button";
import {InputComponent} from "../input/input.component";
import {NgIf} from "@angular/common";
import {ConfigService} from "../config.service";
import {parseFrenchDate} from "../../tools";

@Component({
  selector: 'app-events',
  standalone: true,
  imports: [
    DbtableComponent,
    MatButton,
    InputComponent,NgIf
  ],
  templateUrl: './events.component.html',
  styleUrl: './events.component.sass'
})
export class EventsComponent implements OnInit {
  events: any[]=[]
  showAddEvent: boolean=false
  new_event: any={title:"",description:"",date:now(),address:"",link:""}

  constructor(public routes:ActivatedRoute,
              public api:ApiService,
              public router:Router,
              public config:ConfigService) {
  }

  async ngOnInit() {
    let isConnected = await checkLogin(this)
    if (true) {
      let params:any=await getParams(this.routes);
      this.new_event={
        title:params.title || "",
        description:params.description || "",
        start:(params.start=="now" ? now("date") : params.start),
        hour: (params.hour=="now" ? now("time") : params.hour),
        Profil:params.id,
        address:params.address || "",
        visual:params.visual || "",
        link:params.link || ""
      }
      this.showAddEvent=(params.title && params.start)
      this.api._get("/events/","profil="+params.id).subscribe((events:any)=>{
          this.events=events.results
        })
    } else {
      this.router.navigate(["login"],{queryParams:{url:"events"}})
    }
  }

  save_event() {
    let body=this.new_event;
    body.dtStart=parseFrenchDate(this.new_event.start+" "+this.new_event.hour).toISOString()
    this.api._post("events","",body).subscribe((r:any)=>{showMessage(this,"Enregisré")})
  }
}
