<!--<app-tuto   title="<br><br><br><br><br><strong>Les films</strong>"-->
<!--            color="white"-->
<!--            image=""-->
<!--            subtitle="Consulter l'ensemble des oeuvres sur lesquelles ont travaillé les anciens"-->
<!--            background="./assets/img/movies.jpeg">-->
<!--</app-tuto>-->

<div class="mainform">

  <app-tuto [if]="pows?.length>0"
            label="Pour chaque film, il est possible de naviguer vers les sites IMDB ou UniFrance" ></app-tuto>


  <br><br>
  <mat-form-field appearance="outline" class="search_zone mat-headline-6" autofocus>
    <input matInput matNativeControl autofocus
           title="Un ou plusieurs mots pour effectuer des recherches dans les métiers, les noms, les réalisations ..."
           (keyup)="onQuery($event)" [(ngModel)]="query.value"
           placeholder="Rechercher par titre, année de sortie, genre du film">
    <mat-icon *ngIf="query.value?.length>0"
              matSuffix style="cursor: pointer"
              (click)="clearQuery()">cancel</mat-icon>
  </mat-form-field>


  <br>
  <div style="font-size:medium;opacity: 0.6;"
       *ngIf="(!query || !query.value || query.value=='') && config?.infos_server?.content && pows.length==0">
    parmi les {{config.infos_server.content!.films}} oeuvres référencées
    <mat-icon style="display: inline-block;margin-bottom: -5px;cursor: pointer;" (click)="open_chart()">bar_chart</mat-icon>
  </div>

  <br>

  <div class="div-center" *ngIf="message.length==0">
    <div *ngIf="pows.length==limit">+ de {{pows.length}} films</div>
    <span *ngIf="pows.length<limit && pows.length>10">{{pows.length}} films</span>
    <div style="display:inline-block;cursor: pointer;font-style: italic;font-size: small;" (click)="show_all()">
      <span *ngIf="limit==50">- En voir plus</span>
    </div>
    <br>
  </div>

  <div class="div-center">
    <app-hourglass [message]="message"></app-hourglass>
  </div>

  <mat-accordion #powAccordion="matAccordion" [multi]="true">
    <div class="wrap-list" style="width:100%;">
      <mat-expansion-panel *ngFor="let pow of pows"
                           class="wrap-item" hideToggle style="margin:8px;"
                           (afterExpand)="get_pow(pow)"
                           (afterCollapse)="pow.expanded=false"
                           [expanded]="pow.expanded">

        <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*" style="padding: 5px;">
          <table style="width: 100%;">
            <tr style="vertical-align: top;text-align: left;">
              <td *ngIf="pow.visual?.length>0 && pow.expanded && config.hasPerm('r_movie_picture')" style="width: 110px;">
                <img [src]="pow.visual"
                     class="image-photo"
                     style="width: 100px;">
              </td>
              <td style="padding: 3px;text-align: left;width: max-content;">
                  <div class="mat-subheading-2" style="color:white;">
                    <table style="width:100%;"><tr >
                      <td>{{pow.title}}</td>
                      <td style="text-align: left;width: 35px;" class="expanded-plus mat-headline-6" *ngIf="pows.length>1">
                        <span *ngIf="pow.expanded">-</span>
                        <span *ngIf="!pow.expanded">+</span>
                      </td>

                    </tr></table>


                  </div>
                  <hr class="dark_separator">
                  <table style="color:white;text-align: left;width:100%;">
                    <tr>
                      <td class="mat-body-1 label">
                        {{pow.nature}} - {{pow.category}} - {{pow.year}}<br>
                        <div style="font-size: small;line-height: 97%;text-align: left;padding:5px;width: 90%;display: inline-block;"
                             *ngIf="config.hasPerm('r_movie_synopsis')">
                          {{pow.description}}
                        </div>

                        <table style="margin: 0;padding: 0;">
                          <tr *ngIf="pow.budget>0">
                            <td>Budget</td><td>{{pow.budget}}€</td>
                          </tr>
                          <tr *ngIf="pow.production">
                            <td>Production</td><td>{{pow.production}}</td>
                          </tr>
                          <tr style="vertical-align: middle;" *ngIf="pow.links?.length>0 && config.hasPerm('r_movie_links')">
                            <td>Liens</td>
                            <td>
                              <div>
                                <div *ngFor="let link of pow.links" style="display: inline-block;">
                                  <div *ngIf="link.url?.length>0" style="display: inline-block;margin:2px;margin-left:5px;">
                                    <a href="{{link.url}}" target="_blank" class="label">
                                      {{link.text.replace("auto:","").toUpperCase()}}
                                    </a>
                                  </div>
                                  <div *ngIf="link.url?.length==0" style="display: inline-block;">
                                    {{link.text.replace("auto:","")}}
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </td>

                    </tr>
                  </table>

              </td>
            </tr>
          </table>
        </mat-expansion-panel-header>



        <div *ngIf="pow.works?.length>0" style="line-height: 95%;width:100%;text-align: left;">
          <hr class="dark_separator">
          <table style="width: 100%;">
            <tr *ngFor="let work of pow.works"
                style="cursor: pointer;min-width: 400px;"
                (click)="open_search(work)">
              <td style="width: 45%;text-align: left;" class="mat-body-2 label">{{work.job.toUpperCase()}}</td>
              <td style="width: 45%;" class="mat-body-2">{{work.name}}</td>
              <td style="width: 10px;text-align: right;">
                <mat-icon style="font-size: medium;padding:0px;margin:0;height: 17px;"
                          title="Cliquer pour consulter le profil">visibility</mat-icon>
              </td>
            </tr>
          </table>
        </div>

        <br>
        <hr class="dark_separator">

        <div *ngIf="pow.award?.length>0 && config.hasPerm('r_movie_awards')" style="text-align: left;" class="mat-body-2">
          <br><div class="label">Récompenses</div><br>
          <table style="width: 100%;text-align: left;vertical-align:text-top;">
            <tr *ngFor="let a of award_filter(pow.award,true)" style="width:100%;">
              <td [title]="a.description" style="color:lightgrey;width:80%;">
                <div style="margin-bottom: 8px">{{a.festival.title}} / {{a.description}}</div>
              </td>
              <td style="width: 10px;text-align: right;">
                {{a.year}}
              </td>
            </tr>
          </table>

          <br><div class="label">Nominations</div><br>
          <table style="width: 100%;text-align: left;vertical-align:text-top;">
            <tr *ngFor="let a of award_filter(pow.award,false)" style="width:100%;">
              <td [title]="a.description" style="color:lightgrey;width:80%;">
                <div style="margin-bottom: 8px">{{a.festival.title}} / {{a.description}}</div>
              </td>
              <td style="width: 10px;text-align: right;">
                {{a.year}}
              </td>
            </tr>
          </table>
        </div>


<!--        Liste des commandes disponibles -->
        <mat-action-row>
          <div style="width: 100%;text-align: center">
            <button class="app-button"
                    *ngIf="config.isLogin() && config.user.profil?.length>0"
                    mat-button
                    (click)="add_experience(pow)">
              <div class="bloc-bouton">
                Ajouter en<br>expérience
              </div>

            </button>

            <button mat-icon-button
                    class="icon-button"
                    title="Partager la fiche de ce film"
                    *ngIf="config.hasPerm('share')"
                    (click)="share(pow)">
              <mat-icon>share</mat-icon>
            </button>

            <button mat-icon-button
                    class="icon-button"
                    title="Partager la fiche de ce film"
                    *ngIf="config.hasPerm('quality')"
                    (click)="quality(pow)">
              <mat-icon>check_box</mat-icon>
            </button>

            <button mat-icon-button
                    class="icon-button"
                    title="Réanalyse du film"
                    *ngIf="config.hasPerm('add_movies')"
                    (click)="analyse(pow)">
              <mat-icon>build</mat-icon>
            </button>

            <button mat-icon-button
                    class="icon-button"
                     title="Rechercher avec Google"
                    (click)="openGoogle(pow)">
              <mat-icon>search</mat-icon>
            </button>


            <button mat-icon-button
                    class="icon-button"
                    title="Enlever le film"
                    *ngIf="config.hasPerm('delete_pow')"
                    (click)="deletePow(pow)">
              <mat-icon>delete</mat-icon>
            </button>

            <button mat-icon-button
                    class="icon-button"
                    (click)="router.navigate(['faqs'],{queryParams:{open:'fiche_film'}})">
              <mat-icon>help</mat-icon>
            </button>
          </div>


        </mat-action-row>

      </mat-expansion-panel>
    </div>
  </mat-accordion>
</div>
