<div class="mainform">
  <br>
  <h2>Importer des profils</h2>
  <app-hourglass [message]="message"></app-hourglass>
  <br>

  <app-upload-file (uploaded)="import($event,'profil')"
                   [zone]="true" style="display: inline-block;"
                   width="100px" height="75px" [maxsize]="100000000"
                   extensions=".csv,.xls,.xlsx">
    Ajouter un fichier Excel ou CSV des Profils
  </app-upload-file>
  <br>
  <app-upload-file (uploaded)="import($event,'pow')"
                   [zone]="true" style="display: inline-block;"
                   width="100px" height="75px" [maxsize]="100000000"
                   extensions=".csv,.xls,.xlsx">
    Ajouter un fichier Excel ou CSV des Oeuvres
  </app-upload-file>

  <br>
  <app-upload-file (uploaded)="import($event,'work')"
                   [zone]="true" style="display: inline-block;"
                   width="100px" height="75px" [maxsize]="100000000"
                   extensions=".csv,.xls,.xlsx">
    Ajouter un fichier Excel ou CSV des Travaux
  </app-upload-file>



  <br>
<!--  <br>-->
<!--  <mat-form-field appearance="fill"-->
<!--                  style="width:90%;max-width: 300px;">-->
<!--    <mat-label>Ajouter des colonnes pour compléter les enregistrements</mat-label>-->
<!--    <textarea matInput-->
<!--              [rows]="3"-->
<!--              [(ngModel)]="dict">-->
<!--    </textarea>-->
<!--    <mat-icon matSuffix style="cursor: pointer"-->
<!--              (click)="dict='{}'">cancel</mat-icon>-->
<!--  </mat-form-field>-->

  <br>

<!--  <a href="./assets/modele_import.csv">Télécharger le modèle d'importation</a>-->

  <br><br>

  <h2>Connexion vers OASIS</h2>

    DataCulture utilise OASIS comme source d'information à partir des fichiers excel issue des lien suivants:<br>
    <div style="display: inline-block;with:500px;text-align: left;">
      <ul>
        <li><a href="https://femis.scolasis.com/#type=LIST&name=STUDENT_FC" target="OASIS">Liste des stagiaires formation PRO</a></li>
        <li><a href="https://femis.scolasis.com/#type=LIST&name=STUDENT_FI" target="OASIS">Etudiants en cours d'étude</a></li>
        <li><a href="https://femis.scolasis.com/#type=LIST&name=STUDENT_FI_ALUMNI" target="OASIS">Liste des anciens étudiants</a></li>
      </ul>
    </div>


</div>
