<div class="mainform">
  <div class="titleform">Questions fréquentes</div>
  <div style="width:fit-content;display: inline-block;">

    <mat-expansion-panel [expanded]="faq.visible" togglePosition="before"
                          style="margin:5px;"
                         (expandedChange)="open_faq($event,faq)"
                         *ngFor="let faq of faqs"
                         name="faq">

      <mat-expansion-panel-header collapsedHeight="60px" expandedHeight="60px">
      <span *ngIf="!faq.visible" class="faq-title">
        {{faq.title}}
      </span>
        <span *ngIf="faq.visible">
        {{faq.title}}
      </span>
      </mat-expansion-panel-header>

      <div style="height:auto;">
        <div style="font-size: small"
             class="faq-content"
             *ngIf="!faq.content.endsWith('html') && faq.format!='html'">
          {{faq.content}}
        </div>

        <iframe style="border:none;width:100%;"
                *ngIf="faq.content.endsWith('html')"
                [src]="faq.content">
        </iframe>

        <div [innerHTML]="faq.content"
             class="faq-content"
             *ngIf="faq.format=='html'">
        </div>
      </div>

    </mat-expansion-panel>
  </div>

</div>

