<div class="mainform">
  <br>
  <span *ngIf="nfts?.length==0">Aucun diplôme enregistré dans la blockchain pour l'instant</span>

    <mat-card *ngFor="let t of nfts" name="card" class="app-card pmat-elevation-z8" style="display: inline-block;max-width:200px;max-height: 150px;">
      <mat-card-header name="cardHeader">
        <mat-card-title name="cardTitle">
          {{t.name}}
        </mat-card-title>
      </mat-card-header>

      <mat-card-content name="cardContent">
        {{t.attributes}}
      </mat-card-content>
    </mat-card>


  <br>
  <a *ngIf="nfts?.length>0" [href]="url_explorer" target="_blank">Voir directement dans la blockchain Elrond</a>
</div>
