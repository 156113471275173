<div class="mainform" style="text-align: left !important;margin:10px;padding:10px;">

  <h2>LA POLITIQUE DE CONFIDENTIALITÉ</h2>

  <p>
    Conscient de l’importance d’assurer la confidentialité des données, La FEMIS prend des engagements forts vis-à-vis de la protection des données à caractère personnel.<br>

    Ainsi, cette Politique a pour objet de vous présenter l’ensemble des informations nécessaires à comprendre comment La FEMIS collecte,
    traite et protège vos données personnelles dans le cadre de l’utilisation du site Internet https://www.femis.fr<br>

    La présente politique de confidentialité pourra évoluer en fonction du contexte légal et réglementaire et de la doctrine de la Commission nationale de l’informatique et des libertés (« CNIL »).
  </p>

  <h3>DONNÉES COLLECTÉES</h3>

  <p>
    Lors de l’utilisation de Data Culture Pro, certaines données à caractère personnel concernant l’Utilisateur (les « Données ») pourront être collectées.
    La FEMIS veille à ne collecter et ne traiter que des Données strictement nécessaires eu égard à l’objectif qui est poursuivi.<br>

    Les données à caractère personnel sont toutes les informations qui permettent de vous identifier en tant que personne physique ou de vous reconnaître,
    directement ou indirectement. Il s’agit, par exemple : d'un nom, prénom, date de naissance, sexe, adresse postale, adresse email, adresse IP d'un ordinateur,
    historique de navigation, numéro de téléphone, photo, vidéos, commentaire indiquant vos coordonnées, identifiant et mot de passe personnels et confidentiels... etc.
  </p>

  <h3>FINALITÉS DE LA COLLECTE</h3>

  <p>
    Les traitements de Données mis en œuvre par La FEMIS par l’intermédiaire de Data Culture Pro répondent à des finalités explicites, légitimes et déterminées.<br>

    Les Données sont traitées principalement pour les finalités suivantes :<br>

    la gestion de votre inscription à Data Culture Pro La FEMIS ;
    la gestion de votre compte La FEMIS et de votre profil ainsi que de votre compte sur l’application La FEMIS si vous avez décidez de la télécharger ;
    vous renvoyer vers Data Culture Pro locale La FEMIS dont vous dépendez, en fonction de votre pays ;
    la gestion de vos demandes de contact ;
    la gestion de votre inscription aux newsletters du réseau La FEMIS ;
    la gestion de votre inscription aux événements du réseau La FEMIS ;
    vous permettre de trouver un stage, un emploi, notamment en vous créant des alertes personnalisées ;
    vous envoyer des communications par mail ou SMS sur les nouveautés proposées par La FEMIS ;
    vous permettre de contacter directement d’autres alumni membres et des partenaires recruteurs ;
    être retrouvé par d’autres membres et des partenaires ;
    le fonctionnement de Data Culture Pro, notamment par l’intermédiaire des cookies et autres traceurs ;
    le respect de nos obligations légales et la défense de nos intérêts, le cas échéant.


    Vos Données sont traitées :

    en exécution du contrat qui nous lie (comme nos Conditions générales d’Utilisation, conventions, partenariat…) , et ce, pour vous fournir le(s) service(s) que vous avez requis ;
    à votre demande lorsque vous avez donné votre consentement, notamment pour la réception de newsletter. Dans ce cas, vous pouvez retirer votre consentement à tout moment, via votre espace personnel ou en cliquant sur le liensde désinscription présent dans les communications;
    sur la base de l’intérêt légitime de La FEMIS, par exemple pour l’édition, la sécurité la mise à disposition du Site, la réponse à des autorité, la défense des droits de La FEMIS ;
    sur la base d’obligation légales, notamment en fonction des décrets applicables à La FEMIS.

  </p>

  <h3>DESTINATAIRES DES DONNÉES COLLECTÉES</h3>


  La FEMIS ne communique les Données qu’à des destinataires habilités et déterminés. Ces destinataires sont notamment les suivants :<br>

  les services internes de La FEMIS en charge de ces traitements ;
  les prestataires techniques de La FEMIS ;
  les éventuels partenaires ou co-contractants de La FEMIS ;
  les autres utilisateurs membres de Data Culture Pro Alumni pour les données que vous autorisez à diffuser auprès de ces derniers via l’annuaire ;
  les services concernés du ministère français chargé des Affaires étrangères.


  En outre, La FEMIS transmet vos Données à l’Ambassade de France ou son Service de coopération et d’action culturelle (SCAC),
  et notamment par l’Espace La FEMIS associé, gérant Data Culture Pro locale La FEMIS dont vous dépendez.
  Lorsque cette transmission implique un transfert de données en dehors de l’Union européenne,
  La FEMIS s’assure que l’ensemble des dispositions relatives aux transferts de données en dehors de l’Union européenne sont respectées,
  afin de garantir un niveau de sécurité équivalent à celui garantit en Union européenne. Dans ce contexte, l’Ambassade ou l’Espace
  La FEMIS gérant le site local agit en qualité de responsable de traitement concernant l’ensemble des traitements de données effectués dans le cadre et par le biais du site local La FEMIS.



  <h3>CONSERVATION DES DONNÉES</h3>


  La FEMIS ne conserve vos Données que pour la durée nécessaire aux opérations pour lesquelles elles ont été collectées ainsi que dans le respect de la législation en vigueur.

  Ainsi, par exemple, vos Données sont conservées par La FEMIS tant que vous êtes inscrits sur Data Culture Pro et jusqu’à 5 ans après votre désinscription.



  <h3>VOS DROITS SUR VOS DONNÉES</h3>

  <p>
    Vous avez des droits sur vos Données, dans les limites de la Réglementation. Ainsi, vous avez, à tout moment, le droit de demander à La FEMIS
    l'accès à vos Données, la rectification ou l'effacement de celles-ci,
    le droit à la limitation de leur traitement, le droit de s'opposer à ce traitement ainsi que le droit de demander
    la portabilité des Données. Vous disposez également du droit de définir des directives relatives au sort de vos Données après votre mort.

    Si vous souhaitez exercer ces droits, veuillez-vous adresser à <a href="mailto:alumni@femis.fr">alumni contact</a>
    En cas de doute sur votre identité, nous pourrons vous demander un justificatif d’identité.

    Si vous estimez, après nous avoir contactés, que vos droits ne sont pas respectés vous pouvez adresser une réclamation à la CNIL.
  </p>


  <h3>COOKIES</h3>

  <p>
    Lorsque vous naviguez sur le Site, des cookies peuvent être installés sur votre terminal par La FEMIS ou ses sous-traitants, pour son compte.
    Les cookies sont de petits fichiers stockés temporairement sur le disque dur de votre ordinateur,
    ils sont nécessaires à l’utilisation du Site (pour les cookies techniques) ou permettent de mesurer
    l’audience des services proposés sur le Site afin d’améliorer le Site (cookies de mesure d’audience).

    Vous êtes informé dès votre connexion sur le Site du dépôt de ces cookies via un « bandeau cookies ».

    Les cookies strictement techniques sont nécessaires au fonctionnement du Site, ils sont toujours déposés et ne nécessitent pas votre consentement.

    Les cookies de mesures d’audience (analytics) et les cookies liés aux réseaux sociaux ne sont déposés sur votre terminal
    que si vous y avez consenti en cliquant sur « accepter ». Le traitement de vos Données collectées à partir de ces cookies
    est donc fondé sur votre consentement, que vous pouvez retirer à tout moment.

    Si vous souhaitez modifier ou retirer votre consentement cliquez ici.

    Les cookies ont une durée de vie de 13 mois et les cookies permettant de retenir votre consentement sont conservés 6 mois.
    Les informations collectées à partir des cookies sont conservées au maximum 25 mois.

    Les données collectées à partir des cookies sont traitées conformément à la Réglementation.
  </p>

  <h3>CONTACT</h3>

  <p>
    Pour plus d’informations sur les traitements de vos données vous pouvez contacter le délégué à la protection des données désigné par La FEMIS à l’adresse électronique suivante : <a href="mailto:alumni@femis.fr">alumni contact</a>
  </p>

</div>
