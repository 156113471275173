<div [style.width]="width" style="padding:5px;display: inline-block;text-align: left;line-height: 80%;margin-top:10px;">
	<small><ng-content></ng-content></small>
	<table style="width: 100%;text-align: center;margin:0;">
		<tr>
			<td style="font-size: small;width:15px">
				<mat-icon>thumb_down_alt</mat-icon>
			</td>
			<td>
				<mat-slider style="width:100%;display: inline-block;" [min]="1" [max]="4" [step]="1">
					<input matSliderThumb [value]="value" (valueChange)="onchange($event)">
				</mat-slider>
			</td>
			<td style="font-size: small;width:15px;">
				<mat-icon>thumb_up_alt</mat-icon>
			</td>
		</tr>
	</table>
</div>
