<div class="mainform" style="overflow: hidden;">
  <div id="toolbar"
       class="toolbar-app"
       style="vertical-align:center;padding: 8px;position:absolute;text-align:left;display:block;width:fit-content;height:fit-content;right:0px;top:0px;z-index:100;background-color: #424242;">

    <mat-form-field appearance="outline">
      <mat-label>Propriétés</mat-label>
      <mat-select id="selSelFilter" [(ngModel)]="selFilter">
        <mat-option *ngFor="let p of props" [value]="p">
          {{p}}
        </mat-option>
      </mat-select>
    </mat-form-field>


      <mat-slider
        [title]="selFilter"
        color="primary"
        class="app-slider"
        [(ngModel)]="filter[selFilter].value"
        (valueChange)="updateForces(width,height)"
        [min]="filter[selFilter].min" [max]="filter[selFilter].max" [step]="filter[selFilter].step">
      </mat-slider>


    <mat-form-field appearance="outline">
      <mat-select id="selPromoFilter" [(ngModel)]="filter.promo.value" (selectionChange)="updateData()">
        <mat-option *ngFor="let y of filter.promo.values" [value]="y">
          {{y}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-select id="selFormationFilter" [(ngModel)]="filter.department.value" (selectionChange)="updateData()">
        <mat-option *ngFor="let y of filter.department.values" [value]="y">
          {{y}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-icon (click)="clear_filter()" style="cursor: pointer;">cancel</mat-icon>

    <div *ngIf="sel_node" style="display: inline-block;margin:5px;line-height: 90%;" [innerHTML]="sel_node.label"></div>

  </div>
  <br>

  <app-hourglass [message]="message"></app-hourglass>
  <figure #graph_zone id="graph" style="width: 95%;height:90vh;overflow: hidden;" class="mat-elevation-z4"></figure>
</div>
