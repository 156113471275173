<div class="mainform">
  <app-tuto
    label="Après validation de l'article (bouton Publiez), celui-ci est relu par le responsable editorial de la plateforme qui le publie ou vous envoi un message pour correction">
  </app-tuto>
  <app-hourglass [message]="message"></app-hourglass>
  <br>


  <app-input label="Titre de l'article"
             style="display: inline-block;max-width: 1000px;width:90%;"
             value_type="text"
             [showClear]="true"
             help="Un titre court, percutant pour insiter à la lecture"
             (valueChange)="title=$event">

  </app-input>


  <br>

  <div class="mat-elevation-z12"
       style="max-width:1000px;margin:25px;min-height: 300px;color: black;display: inline-block;width:95%;background-color: lightgrey;">
    <quill-editor id="htmlEditorZone"
                  *ngIf="!onlyPreview && !editorContent.startsWith('http')"
                  [(ngModel)]="editorContent">
    </quill-editor>


    <iframe *ngIf="editorContent.startsWith('http')"
            [src]="editorContent | safe"
            style="width: 100%;height: 100%;">
    </iframe>


    <div *ngIf="onlyPreview && !editorContent.startsWith('http')"
         [innerHTML]="editorContent"
         style="width: 100%;height: 100%;padding:10px;">
    </div>
  </div>
  <br>

  <div *ngIf="editorContent.length>10" style="width:90%;display: inline-block;max-width: 1000px;">
    <app-input label="Résumé" value_type="text"
               [maxlength]="250"
               (valueChange)="resumer=$event"></app-input>

    <br>

    <mat-form-field class="app-field" id="tagEditor" >
      <mat-label>Thèmes de votre article</mat-label>
      <mat-chip-grid #chipList aria-label="Selection des tags">
        <mat-chip-row
          *ngFor="let tag of tags"
          [removable]="removable"
          (removed)="remove(tag)">
          {{tag}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip-row>
        <input
          style="width: 100%;"
          #fruitInput
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)">
      </mat-chip-grid>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
          {{tag}}
        </mat-option>
      </mat-autocomplete>
      <mat-hint>Vos lecteur peuvent filtrer les articles par thématique</mat-hint>
    </mat-form-field>

  </div>


  <br>
  <app-tuto label="Les tags vont permettre aux lecteurs de filtrer les articles par thème"></app-tuto>
  <app-tuto [if]="onlyPreview" label="Si la présentation du fichier importer ne convient pas, les modifications doivent être faite depuis l'outil qui a permis sa conception"></app-tuto>
  <app-tuto [if]="title?.length==0 && resumer?.length==0 && editorContent?.length>0" label="Attention, le titre et le résumé sont obligatoires, ils ne doivent pas être inclus dans le corps de l'article"></app-tuto>


</div>

<div class="bottom-bar">

  <input #csvInput hidden="true"
         type="file" onclick="this.value=null"
         (change)="_import($event)"
         accept=".html,.htm"/>

  <div style="display:inline-block;margin-bottom: -15px;">
    <button class="icon-button" mat-icon-button
            id="cmdReset"
            title="Effacer l'article en cours"
            (click)="clear_article()">
      <mat-icon>delete</mat-icon>
    </button>
    <button class="icon-button" mat-icon-button
            id="cmdSave"
            [disabled]="!editorContent || editorContent.length==0 || title?.length==0 || resumer?.length==0"
            title="Enregistrer un brouillon"
            (click)="save()">
      <mat-icon>save</mat-icon>
    </button>
  </div>


  <button class="app-button" mat-raised-button
          id="cmdImport"
          title="Importer"
          (click)="csvInput.click()">
    <div class="bloc-bouton">Importer un<br>fichier html</div>
  </button>



  <button class="app-button" mat-raised-button
          id="cmdImportImage"
          title="Importer"
          (click)="import_image()">
    Images
  </button>


  <button class="app-button" mat-raised-button
          id="cmdPastFromShare"
          title="Coller un article depuis facebook"
          (click)="import_share()">
    Import réseau
  </button>




  <button class="app-button" mat-raised-button
          [disabled]="!editorContent || editorContent.length==0 || title?.length==0  || resumer?.length==0"
          id="cmdClear"
          color="primary"
          (click)="publish()">
    <mat-icon>send</mat-icon>&nbsp;
    <div class="bloc-bouton">Envoyer pour<br>publication</div>
  </button>
</div>

