<div class="mainform" style="width:100%;text-align: center;">
  <br>
  <h1>Data Culture Pro</h1>

  <br><br>
  <app-hourglass message=" "
                 diameter="40"
                 fontsize="20px"></app-hourglass>
  <br><br>
</div>
